import { Table } from "@Ignite-Reading/ui-kit/components";
import * as PropTypes from "prop-types";
import { Suspense } from "react";
import { Await } from "react-router-dom";

import TableDataRenderer from "./TableDataRenderer";

const EventSourceTable = ({
  children,
  columns,
  eventType,
  itemsPerPage,
  noDataMessage,
  pageSearchKey,
  paginationAriaLabel,
  resolve,
  url,
}) => (
  <Suspense fallback={<Table columns={columns} />}>
    <Await
      resolve={resolve}
      errorElement={
        <Table
          columns={columns}
          errorMessage="Something went wrong. Please try again later."
          hasError
        />
      }>
      {({ data, pagination, lastUpdated }) => (
        <TableDataRenderer
          columns={columns}
          eventType={eventType}
          initialData={data}
          initialPagination={pagination}
          initialLastUpdated={lastUpdated}
          itemsPerPage={itemsPerPage}
          noDataMessage={noDataMessage}
          pageSearchKey={pageSearchKey}
          paginationAriaLabel={paginationAriaLabel}
          url={url}>
          {children}
        </TableDataRenderer>
      )}
    </Await>
  </Suspense>
);

EventSourceTable.propTypes = {
  children: PropTypes.func.isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  eventType: PropTypes.string.isRequired,
  itemsPerPage: PropTypes.number.isRequired,
  noDataMessage: PropTypes.string.isRequired,
  pageSearchKey: PropTypes.string.isRequired,
  paginationAriaLabel: PropTypes.string.isRequired,
  resolve: PropTypes.shape({
    catch: PropTypes.func.isRequired,
    then: PropTypes.func.isRequired,
  }).isRequired,
  url: PropTypes.string.isRequired,
};

export default EventSourceTable;
