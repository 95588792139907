import * as PropTypes from "prop-types";

import { Dialog } from "@Ignite-Reading/ui-kit/components";

const SessionPlaybackDialog = ({ date, index, playbackURL, startTime }) => (
  <Dialog>
    <Dialog.Trigger className="text-brand-500 hover:text-brand-700 hover:underline">
      View Recording {index}
    </Dialog.Trigger>
    <Dialog.Body className="h-full md:max-w-8xl	max-w-8xl">
      <Dialog.Heading>
        <Dialog.Title className="text-xl">
          Viewing Recording {index}
        </Dialog.Title>
      </Dialog.Heading>
      <Dialog.Content>
        <iframe
          className="w-full h-full"
          id="session-playback"
          src={playbackURL}
          title={`Viewing recording ${index} from the session on ${date} at ${startTime}`}
        />
      </Dialog.Content>
    </Dialog.Body>
  </Dialog>
);

SessionPlaybackDialog.propTypes = {
  date: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  playbackURL: PropTypes.string.isRequired,
  startTime: PropTypes.string.isRequired,
};

export default SessionPlaybackDialog;
