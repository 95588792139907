import { useContext } from "react";

import SessionDetailsDialogContext from "./context";

const SessionDetailsDialogTrigger = props => {
  const { open, setOpen, setProps } = useContext(SessionDetailsDialogContext);
  const onClick = () => {
    setProps(props);
    setOpen(true);
  };

  return (
    <button
      aria-haspopup="dialog"
      aria-expanded={open}
      className="pl-1 text-xs text-brand-500 text-left hover:text-brand-700 hover:underline"
      data-testid="view-session-details-btn"
      onClick={onClick}
      type="button">
      View Session Details
    </button>
  );
};

export default SessionDetailsDialogTrigger;
