import Api from "services/Api/Class";

const LIVE_SESSIONS_PER_PAGE = 8;
const UPCOMING_SESSIONS_PER_PAGE = 8;

export default class SessionService extends Api {
  constructor(opts) {
    super(opts);
    this.authClient = opts.authClient;
  }

  getSession({ signal, uuid }) {
    return this.fetch({
      url: "/sessions",
      params: { uuid },
      signal,
    });
  }

  getSessions({ page = 1, searchParam = null, signal }) {
    return this.fetch({
      url: "/sessions/paginated",
      params: { page, search_param: searchParam },
      signal,
    });
  }

  getUpcomingSessions({ page = 1, searchParam = null, signal }) {
    return this.fetch({
      url: "/sessions/upcoming",
      params: {
        items: UPCOMING_SESSIONS_PER_PAGE,
        page,
        search_param: searchParam,
      },
      signal,
    });
  }

  getLiveSessions({ page = 1, searchParam = null, signal }) {
    return this.fetch({
      url: "/sessions/live",
      params: {
        items: LIVE_SESSIONS_PER_PAGE,
        page,
        search_param: searchParam,
      },
      signal,
    });
  }

  getPastSessions({ page = 1, searchParam = null, signal }) {
    return this.fetch({
      url: "/sessions/past",
      params: { page, search_param: searchParam },
      signal,
    });
  }

  getLessonSpaceSessionPlayback({ sessionId, signal }) {
    return this.fetch({
      url: "/lesson_space_session_playbacks",
      params: { session_id: sessionId },
      signal,
    });
  }

  handleJoinSession(session_id, user_uuid) {
    return this.fetch({
      url: "/user_sessions",
      method: "POST",
      body: { session_id, user_uuid },
    });
  }
}
