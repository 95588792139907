import { AdminNavigation } from "@Ignite-Reading/ui-kit/components";
import { useAuth } from "@Ignite-Reading/ui-kit/auth";
import { Outlet } from "react-router-dom";

const AuthLayout = () => {
  const { user } = useAuth();

  return (
    <main>
      <AdminNavigation
        currentUser={`${user?.first_name ?? ""} ${user?.last_name ?? ""}`}
      />
      <div className="lg:pl-64 flex flex-col min-h-screen pb-10">
        <Outlet />
      </div>
    </main>
  );
};

export default AuthLayout;
