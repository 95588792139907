import noop from "lodash/noop";
import { createContext } from "react";

const SessionDetailsDialogContext = createContext({
  open: false,
  props: null,
  setOpen: noop,
  setProps: noop,
});

export default SessionDetailsDialogContext;
