import { useEffect, useMemo, useState } from "react";

import SessionService from "services/Session";

const useGetSession = uuid => {
  const [data, setData] = useState({});
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    const controller = new AbortController();

    SessionService.getSession({ signal: controller.signal, uuid })
      .then(response => {
        if (response.status === 200) {
          setData(response.data);
        }
        if (response.error) {
          setHasError(true);
        }
      })
      .catch(() => {
        setHasError(true);
      });

    return () => {
      controller.abort();
    };
  }, [uuid]);

  return useMemo(
    () => ({
      data,
      hasError,
    }),
    [data, hasError]
  );
};

export default useGetSession;
