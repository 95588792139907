import { Table } from "@Ignite-Reading/ui-kit/components";
import { useEventSource } from "@Ignite-Reading/ui-kit/hooks";
import * as PropTypes from "prop-types";
import { useCallback, useMemo } from "react";
import { useSearchParams } from "react-router-dom";

import { BASE_10, SEARCH_TERM_SEARCH_PARAM } from "constants";
import ConnectionStatusTooltip from "./ConnectionStatusTooltip";

const {
  VITE_SESSION_SERVICE_VERSION: apiVersion,
  VITE_SESSION_SERVICE_API_URL: base,
} = import.meta.env;

const TableDataRenderer = ({
  children,
  columns: columnsFromProps,
  eventType,
  initialData,
  initialPagination,
  initialLastUpdated,
  itemsPerPage,
  noDataMessage,
  pageSearchKey,
  paginationAriaLabel,
  url: urlPath,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const currentPage = parseInt(searchParams.get(pageSearchKey) ?? 1, BASE_10);
  const onChangePage = useCallback(
    page => {
      setSearchParams(prev => ({
        ...Object.fromEntries(prev.entries()),
        [pageSearchKey]: page,
      }));
    },
    [pageSearchKey, setSearchParams]
  );
  const url = useMemo(() => {
    const url = new URL(`/api/${apiVersion}/${urlPath}`, base);

    url.searchParams.append("items", itemsPerPage.toString());
    url.searchParams.append("page", currentPage.toString());
    if (searchParams.has(SEARCH_TERM_SEARCH_PARAM)) {
      url.searchParams.append(
        "search_param",
        searchParams.get(SEARCH_TERM_SEARCH_PARAM)
      );
    }

    return url.toString();
  }, [currentPage, itemsPerPage, searchParams, urlPath]);
  const {
    data: eventSourceData,
    lastUpdated: eventSourceLastUpdated,
    status,
  } = useEventSource(url, eventType);
  const data = eventSourceData ? eventSourceData[1] : initialData;
  const pagination = eventSourceData ? eventSourceData[0] : initialPagination;
  const lastUpdated = eventSourceLastUpdated ?? initialLastUpdated;
  const columns = [
    ...columnsFromProps,
    ...(lastUpdated
      ? [
          {
            "aria-label": "Last updated",
            id: "actions",
            label: (
              <ConnectionStatusTooltip
                lastUpdated={lastUpdated}
                status={status}
              />
            ),
          },
        ]
      : []),
  ];

  return (
    <Table
      columns={columns}
      data={data}
      noDataMessage={noDataMessage}
      pagination={{
        "aria-label": paginationAriaLabel,
        count: pagination.pages,
        onChange: onChangePage,
        page: currentPage,
      }}>
      {data => data.map(row => children(row, columns.length))}
    </Table>
  );
};

TableDataRenderer.propTypes = {
  children: PropTypes.func.isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  currentPage: PropTypes.number,
  eventType: PropTypes.string.isRequired,
  initialData: PropTypes.array.isRequired,
  initialLastUpdated: PropTypes.instanceOf(Date),
  initialPagination: PropTypes.object.isRequired,
  itemsPerPage: PropTypes.number.isRequired,
  noDataMessage: PropTypes.string.isRequired,
  pageSearchKey: PropTypes.string.isRequired,
  paginationAriaLabel: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

export default TableDataRenderer;
