import { useAuth } from "@Ignite-Reading/ui-kit/auth";
import { useToast } from "@Ignite-Reading/ui-kit/components";
import PropTypes from "prop-types";
import { useState } from "react";

import PresencePill, {
  ONLINE,
  NOT_PRESENT,
  LEFT,
} from "components/PresencePill";
import SessionDetailsDialog from "components/SessionDetailsDialog";
import { generateStudentEditLink, generateStudentLATLink } from "utils";
import SessionService from "services/Session";

const LiveSessionItem = ({
  admin,
  sessionId,
  student,
  studentStatus,
  studentUuid,
  tutor,
  tutorStatus,
  userSessionOverrideClaimed,
  uuid,
}) => {
  const [isJoiningSession, setIsJoiningSession] = useState(false);
  const { user } = useAuth();
  const toast = useToast();
  const user_uuid = user?.ignite_uuid;

  const handleJoinClick = () => {
    toast.loading("Hang tight! Joining session…");
    setIsJoiningSession(true);
    SessionService.handleJoinSession(sessionId, user_uuid)
      .then(response => {
        if (response.status !== 200) {
          throw new Error(`HTTP error: ${response.status}`);
        }
        return response.data;
      })
      .then(data => {
        if (data && data.client_url) {
          window.open(data.client_url, "_blank");
          window.open(generateStudentLATLink(studentUuid), "_blank");
          setIsJoiningSession(false);
          toast.dismiss();
        }
      })
      .catch(() => {
        setIsJoiningSession(false);
        toast.error("Oops! Unable to join session.");
      });
  };

  return (
    <div className="flex items-center justify-between py-3.5 px-5 space-x-4 w-full group-last:rounded-b-lg">
      <div className="space-y-0.5">
        <ul className="flex flex-wrap">
          {admin ? (
            <li className="p-1">
              <PresencePill
                data-testid="admin-pill"
                isSessionMonitor
                status={ONLINE}>
                {admin}
              </PresencePill>
            </li>
          ) : null}
          <li className="p-1">
            <PresencePill
              data-testid="tutor-pill"
              status={tutorStatus}
              isSub={userSessionOverrideClaimed}>
              {tutor}
            </PresencePill>
          </li>
          <li className="p-1">
            <PresencePill
              data-testid="student-pill"
              hideStatusDot
              href={generateStudentEditLink(studentUuid)}
              status={studentStatus}>
              {student}
            </PresencePill>
          </li>
        </ul>
        <SessionDetailsDialog.Trigger
          disableJoinButton={isJoiningSession}
          handleJoinClick={handleJoinClick}
          showJoinSessionButton={true}
          title="Live session"
          uuid={uuid}
        />
      </div>
      <div>
        <button
          disabled={isJoiningSession}
          onClick={handleJoinClick}
          data-testid="admin-join-btn">
          Join
        </button>
      </div>
    </div>
  );
};

LiveSessionItem.propTypes = {
  admin: PropTypes.string,
  sessionId: PropTypes.number,
  student: PropTypes.string,
  studentStatus: PropTypes.oneOf([LEFT, NOT_PRESENT, ONLINE]),
  studentUuid: PropTypes.string,
  tutor: PropTypes.string,
  tutorStatus: PropTypes.oneOf([LEFT, NOT_PRESENT, ONLINE]),
  userSessionOverrideClaimed: PropTypes.bool.isRequired,
  uuid: PropTypes.string.isRequired,
};

export default LiveSessionItem;
