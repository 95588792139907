import { useEffect, useMemo, useState } from "react";

import SessionService from "services/Session";

const useGetLessonSpaceSessionPlaybacks = sessionId => {
  const [data, setData] = useState([]);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    const controller = new AbortController();

    SessionService.getLessonSpaceSessionPlayback({
      sessionId,
      signal: controller.signal,
    })
      .then(response => {
        if (response.status === 200) {
          setData(response.data);
        }
        if (response.error) {
          setHasError(true);
        }
        setHasLoaded(true);
      })
      .catch(() => {
        setHasError(true);
        setHasLoaded(true);
      });

    return () => {
      controller.abort();
    };
  }, [sessionId]);

  return useMemo(
    () => ({
      data,
      hasError,
      hasLoaded,
    }),
    [data, hasError, hasLoaded]
  );
};

export default useGetLessonSpaceSessionPlaybacks;
