import SessionDetailsDialogProvider from "./Provider";
import SessionDetailsDialogTrigger from "./Trigger";

/*
 * SessionDetailsDialog is rendered within LiveSessionItem and UpcomingSessionItem.
 * Both LiveSessionItem and UpcomingSessionItem are part of lists that update automatically via EventSource event listeners (or Server-Sent Events).
 * Consequently, LiveSessionItem and UpcomingSessionItem can mount or unmount automatically without user interaction.
 * If SessionDetailsDialog was open when LiveSessionItem or UpcomingSessionItem gets unmounted, the dialog would also be removed because portals are destroyed when their parents are unmounted.
 * To prevent this, we use SessionDetailsDialogProvider to render the dialog at the root level, making sure this way it remains mounted even if LiveSessionItem or UpcomingSessionItem are unmounted.
 * Finally, we use SessionDetailsDialogTrigger to pass all the necessary props to the dialog and then open it.
 */
const SessionDetailsDialog = {
  Provider: SessionDetailsDialogProvider,
  Trigger: SessionDetailsDialogTrigger,
};

export default SessionDetailsDialog;
