import { Tooltip } from "@Ignite-Reading/ui-kit/components";
import { TZDate } from "@date-fns/tz";
import { format } from "date-fns";
import * as PropTypes from "prop-types";

const ConnectionStatusTooltip = ({ lastUpdated, status }) => (
  <div className="w-full text-right text-zinc-400 text-xs font-normal">
    <Tooltip>
      <Tooltip.Trigger>
        <button
          className="underline decoration-dotted cursor-pointer"
          data-testid="real-time-updater-label">
          {status === EventSource.OPEN
            ? "Updating in real-time"
            : "Not updated"}
        </button>
      </Tooltip.Trigger>
      <Tooltip.Content className="p-0 text-xs text-zinc-600 min-w-[210px]">
        <div
          className="text-left px-3.5 pt-2.5 pb-2 flex items-center space-x-2 border-b border-zinc-200"
          data-testid="real-time-updater-popup">
          <span className="relative w-2.5 h-2.5">
            {status === EventSource.OPEN ? (
              [
                <span
                  className="absolute w-full h-full animate-[ping_1.2s_ease-in-out_infinite] bg-lime-500/50 rounded-full"
                  key="ping"
                />,
                <span
                  className="absolute w-full h-full bg-lime-500 rounded-full"
                  key="status"
                />,
              ]
            ) : (
              <span className="absolute w-full h-full bg-zinc-300 rounded-full" />
            )}
          </span>
          <span data-testid="sse-header">
            {status === EventSource.OPEN
              ? "Real-time updating..."
              : "Real-time updates unavailable"}
          </span>
        </div>
        <div className="text-left px-3.5 py-2.5 space-y-1">
          <div className="text-zinc-400 uppercase font-semibold text-[10px]">
            Last updated:
          </div>
          <div className="grid gap-1 grid-cols-[max-content_auto]">
            <div
              className="text-right"
              data-testid="last-updated-eastern">
              {format(new TZDate(lastUpdated, "US/Eastern"), "h:mm:ss a")}{" "}
            </div>
            <div>(Eastern time)</div>
            <div
              className="text-right"
              data-testid="last-updated-local">
              {format(lastUpdated, "h:mm:ss a")}
            </div>{" "}
            <div>(Local time)</div>
          </div>
        </div>
      </Tooltip.Content>
    </Tooltip>
  </div>
);

ConnectionStatusTooltip.propTypes = {
  lastUpdated: PropTypes.instanceOf(Date).isRequired,
  status: PropTypes.oneOf([
    EventSource.CONNECTING,
    EventSource.OPEN,
    EventSource.CLOSED,
  ]).isRequired,
};

export default ConnectionStatusTooltip;
