import { Skeleton } from "@Ignite-Reading/ui-kit/components";

const LoadingSkeleton = () => (
  <Skeleton
    className="py-1 h-full grow"
    width="100%"
    height="100%">
    <Skeleton.Rect
      x="0"
      y="8"
      rx="3"
      ry="3"
      width="160"
      height="20"
    />
    <Skeleton.Rect
      x="0"
      y="46"
      rx="3"
      ry="3"
      width="200"
      height="12"
    />
    <Skeleton.Rect
      x="0"
      y="64"
      rx="3"
      ry="3"
      width="200"
      height="12"
    />
    <Skeleton.Rect
      x="0"
      y="142"
      rx="3"
      ry="3"
      width="160"
      height="20"
    />
    <Skeleton.Rect
      x="0"
      y="180"
      rx="3"
      ry="3"
      width="120"
      height="12"
    />
    <Skeleton.Rect
      x="0"
      y="210"
      rx="3"
      ry="3"
      width="200"
      height="12"
    />
    <Skeleton.Rect
      x="0"
      y="238"
      rx="3"
      ry="3"
      width="200"
      height="12"
    />
  </Skeleton>
);

export default LoadingSkeleton;
