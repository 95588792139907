import { useRouteError } from "react-router-dom";

import RootLayout from "layouts/Root";

export default function ErrorBoundary() {
  const error = useRouteError();

  return (
    <RootLayout>
      <section>
        <h1 data-testid="error-status">{error.status}</h1>
        <h2 data-testid="error-message">{error.message}</h2>
        <p>Uh oh! Something happened!</p>
      </section>
    </RootLayout>
  );
}
