import { defer } from "react-router-dom";

import LiveAndUpcomingSessions from "views/ManageSessions/LiveAndUpcomingSessions";
import SessionService from "services/Session";
import {
  BASE_10,
  LIVE_SESSIONS_PAGE_SEARCH_PARAM,
  SEARCH_TERM_SEARCH_PARAM,
  UPCOMING_SESSIONS_PAGE_SEARCH_PARAM,
} from "constants";

const loader = async ({ request }) => {
  const url = new URL(request.url);
  const liveSessionsPage = parseInt(
    url.searchParams.get(LIVE_SESSIONS_PAGE_SEARCH_PARAM),
    BASE_10
  );
  const upcomingSessionsPage = parseInt(
    url.searchParams.get(UPCOMING_SESSIONS_PAGE_SEARCH_PARAM),
    BASE_10
  );
  const liveSessionsPromise = SessionService.getLiveSessions({
    page: isNaN(liveSessionsPage) ? 1 : liveSessionsPage,
    searchParam: url.searchParams.get(SEARCH_TERM_SEARCH_PARAM),
    signal: request.signal,
  });
  const upcomingSessionsPromise = SessionService.getUpcomingSessions({
    page: isNaN(upcomingSessionsPage) ? 1 : upcomingSessionsPage,
    searchParam: url.searchParams.get(SEARCH_TERM_SEARCH_PARAM),
    signal: request.signal,
  });

  return defer({ liveSessionsPromise, upcomingSessionsPromise });
};

export default {
  element: <LiveAndUpcomingSessions />,
  index: true,
  loader,
};
