import ManageSessionsView from "views/ManageSessions";

import AllSessionsRoute from "./AllSessions";
import PastSessionsRoute from "./PastSessions";
import LiveAndUpcomingSessionsRoute from "./LiveAndUpcomingSessions";

export default {
  element: <ManageSessionsView />,
  path: "/manage_sessions",
  children: [AllSessionsRoute, LiveAndUpcomingSessionsRoute, PastSessionsRoute],
};
