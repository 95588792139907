import { Dialog } from "@Ignite-Reading/ui-kit/components";
import * as PropTypes from "prop-types";
import { useMemo, useState } from "react";

import Content from "./Content";
import SessionDetailsDialogContext from "./context";

const SessionDetailsDialogProvider = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [props, setProps] = useState(null);
  const value = useMemo(
    () => ({
      open,
      setOpen,
      setProps,
    }),
    [open]
  );

  return (
    <SessionDetailsDialogContext.Provider value={value}>
      {children}
      <Dialog
        open={open}
        onOpenChange={setOpen}>
        <Dialog.Body
          className="md:max-w-2xl"
          data-testid="session-details-dialog">
          <Dialog.Heading>
            <Dialog.Title className="text-xl">
              {props?.title ?? ""}
            </Dialog.Title>
          </Dialog.Heading>
          <Dialog.Content className="divide-y divide-zinc-200 space-y-4 text-zinc-700 min-h-[420px] flex flex-col">
            <Content {...props} />
          </Dialog.Content>
        </Dialog.Body>
      </Dialog>
    </SessionDetailsDialogContext.Provider>
  );
};

SessionDetailsDialogProvider.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.node,
};

export default SessionDetailsDialogProvider;
