export default function (error) {
  if (error?.response?.data["message"]) {
    error = error.response.data["message"];
  } else if (error?.Message) {
    error = error.Message;
  } else if (error?.message) {
    error = error.message;
  } else if (typeof error === "object") {
    error = "An unknown error occurred";
  }

  return error;
}
