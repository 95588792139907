import { authLoader } from "@Ignite-Reading/ui-kit/auth";

import authClient from "services/Auth";
import AuthLayout from "layouts/Auth";
import ManageSessionsRoute from "../ManageSessions";

export default {
  element: <AuthLayout />,
  children: [ManageSessionsRoute],
  loader: () => authLoader(authClient),
};
