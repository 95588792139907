import { Tooltip } from "@Ignite-Reading/ui-kit/components";
import PropTypes from "prop-types";

import InformationCircleIcon from "assets/icons/information-circle-mini.svg";

const SessionLegendTooltip = ({ items }) => (
  <Tooltip placement="right-start">
    <Tooltip.Trigger>
      <button
        aria-label="Show legend"
        className="text-zinc-500 hover:text-zinc-700 ml-1.5"
        type="button">
        <InformationCircleIcon />
      </button>
    </Tooltip.Trigger>
    <Tooltip.Content className="text-left">
      <ul className="space-y-1.5">
        {items.map(item => (
          <li
            key={item.label}
            className="flex items-center">
            <div className="flex items-center justify-center h-[20px] w-[20px] p-[1.5px] mr-1.5">
              {item.icon}
            </div>
            {item.label}
          </li>
        ))}
      </ul>
    </Tooltip.Content>
  </Tooltip>
);

SessionLegendTooltip.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.node.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default SessionLegendTooltip;
