import { Table } from "@Ignite-Reading/ui-kit/components";
import { useLoaderData } from "react-router-dom";

import LiveSessionItem from "components/LiveSessionItem";
import UpcomingSessionItem from "components/UpcomingSessionItem";
import SessionLegendTooltip from "components/SessionLegendTooltip";
import CheckCircleIcon from "assets/icons/check-circle-outline.svg";
import FaceRetouchingOffIcon from "assets/icons/face-retouching-off.svg";
import SwapCallsIcon from "assets/icons/swap-calls.svg";
import { NOT_PRESENT, ONLINE } from "components/PresencePill";
import EventSourceTable from "components/EventSourceTable";
import {
  LIVE_SESSIONS_PAGE_SEARCH_PARAM,
  LIVE_SESSIONS_PER_PAGE,
  UPCOMING_SESSIONS_PAGE_SEARCH_PARAM,
  UPCOMING_SESSIONS_PER_PAGE,
} from "constants";

const columns = [{ id: "participants", label: "Participants" }];
const LiveAndUpcomingSessionsView = () => {
  const { liveSessionsPromise, upcomingSessionsPromise } = useLoaderData();

  return (
    <div className="flex space-x-6">
      <div
        className="basis-1/2 space-y-6"
        data-testid="live-sessions-container">
        <div className="flex items-center">
          <h3 className="text-xl font-semibold text-zinc-700 flex items-center py-0.5">
            Live Sessions
            <SessionLegendTooltip
              items={[
                {
                  icon: (
                    <div className="w-[10px] h-[10px] rounded-full bg-red-600" />
                  ),
                  label: "Tutor not present",
                },
                {
                  icon: (
                    <div className="w-[10px] h-[10px] rounded-full bg-yellow-400" />
                  ),
                  label: "Tutor was present then left",
                },
                {
                  icon: <FaceRetouchingOffIcon className="w-full " />,
                  label: "Not present",
                },
                {
                  icon: <CheckCircleIcon className="w-full " />,
                  label: "Present",
                },
                {
                  icon: <SwapCallsIcon className="w-full " />,
                  label: "Swapped out",
                },
              ]}
            />
          </h3>
        </div>
        <EventSourceTable
          columns={columns}
          eventType="live-sessions"
          itemsPerPage={LIVE_SESSIONS_PER_PAGE}
          noDataMessage="There are no sessions to display."
          pageSearchKey={LIVE_SESSIONS_PAGE_SEARCH_PARAM}
          paginationAriaLabel="Live sessions pagination"
          resolve={liveSessionsPromise}
          url="events/live">
          {(row, colSpan) => (
            <Table.Row key={row.id}>
              <Table.Cell
                className="p-0 sm:pl-0"
                colSpan={colSpan}>
                <LiveSessionItem
                  admin={row.admin}
                  district="Ignite School District"
                  school="Martin Luther King Elementary"
                  sessionId={row.id}
                  student={row.student}
                  studentStatus={ONLINE}
                  studentUuid={row.student_uuid}
                  tutor={row.sub_tutor ?? row.primary_tutor}
                  tutorStatus={row.tutor_status || NOT_PRESENT}
                  userSessionOverrideClaimed={row.user_session_override_claimed}
                  uuid={row.uuid}
                />
              </Table.Cell>
            </Table.Row>
          )}
        </EventSourceTable>
      </div>
      <div
        className="basis-1/2 space-y-6"
        data-testid="upcoming-sessions-container">
        <div className="flex items-center">
          <h3 className="text-xl font-semibold text-zinc-700 flex items-center py-0.5">
            Upcoming Sessions
            <SessionLegendTooltip
              items={[
                {
                  icon: <FaceRetouchingOffIcon className="w-full " />,
                  label: "Not present",
                },
                {
                  icon: <SwapCallsIcon className="w-full " />,
                  label: "Swapped out",
                },
              ]}
            />
          </h3>
        </div>
        <EventSourceTable
          columns={columns}
          eventType="upcoming-sessions"
          itemsPerPage={UPCOMING_SESSIONS_PER_PAGE}
          noDataMessage="There are no sessions to display."
          pageSearchKey={UPCOMING_SESSIONS_PAGE_SEARCH_PARAM}
          paginationAriaLabel="Upcoming sessions pagination"
          resolve={upcomingSessionsPromise}
          url="events/upcoming">
          {(row, colSpan) => (
            <Table.Row key={row.uuid}>
              <Table.Cell
                className="p-0 sm:pl-0"
                colSpan={colSpan}>
                <UpcomingSessionItem
                  date={row.date}
                  district="Ignite School District"
                  school="Martin Luther King Elementary"
                  startTime={row.start_time}
                  student={row.student}
                  studentUuid={row.student_uuid}
                  subTutorPending={row.sub_tutor_pending}
                  uuid={row.uuid}
                />
              </Table.Cell>
            </Table.Row>
          )}
        </EventSourceTable>
      </div>
    </div>
  );
};

export default LiveAndUpcomingSessionsView;
